import React, { useState, useRef, useEffect, useCallback } from 'react';
import CommonLayout from '../layout/CommonLayout';
import BackClick from '../components/BackClick';
import CommonButton from '../components/CommonButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function OTPVerification() {
    const location = useLocation();
    const { userId } = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState(['', '', '', '']);
    const inputRefs = useRef([]);
    const [timer, setTimer] = useState(300);
    const { mobileNumber } = location.state || {};
    const [loginstatus, setLoginstatus] = useState();
    const [errorMessage, setErrorMessage] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const formatTimer = (time) => {
        const minutes = String(Math.floor(time / 60)).padStart(2, '0');
        const seconds = String(time % 60).padStart(2, '0');
        return `${minutes}:${seconds} Sec`;
    };

    const handleInputChange = useCallback((index, e) => {
        const value = e.target.value;
        if (value.length <= 1 && /^[0-9]*$/.test(value)) {
            const newValues = [...values];
            newValues[index] = value;
            setValues(newValues);

            if (value && index < inputRefs.current.length - 1) {
                inputRefs.current[index + 1].focus();
            } else if (!value && index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    }, [values]);

    const handleResendClick = useCallback(() => {
        setTimer(300);
    }, []);

    const handleVerifyClick = useCallback(async (e) => {
        e.preventDefault();

        if (loginstatus === 0 && !isCheckboxChecked) {
            setErrorMessage("Please agree to the Terms and Conditions.");
            return;
        }

        const otp = values.join('');
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}customer/otp-verify`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "user_number": mobileNumber,
                    "otp": Number(otp)
                })
            });
            const data = await response.json();
            if (response.ok) {
                localStorage.setItem('token', data?.token);
                localStorage.setItem('usermob', data?.user?.phone);

                const cartData = localStorage.getItem('cart');
                if (cartData && JSON.parse(cartData).length > 0) {
                    navigate(`/${userId}/cart`);
                } else {
                    navigate(`/${userId}`);
                }
            } else {
                setErrorMessage(data.message || "Verification failed. Please try again.");
            }
        } catch (error) {
            setErrorMessage("An error occurred. Please try again later.");
        }
    }, [values, mobileNumber, navigate, userId, loginstatus, isCheckboxChecked]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setTimer(prevTimer => {
                if (prevTimer <= 0) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);
    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}customer/customer-status`;

        fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "mobile": mobileNumber
            })
        })
            .then(response => response.json())
            .then(data => {
                setLoginstatus(data?.status);
            })
            .catch(error => {
                console.error("Error:", error);
            });
    }, [mobileNumber]);

    return (
        <CommonLayout>
            <BackClick />
            <div className='form-fild pt-5 p-3 h-100vh'>
                <div className='inner-det'>
                    <h2>OTP Verification</h2>
                    <p>Enter the OTP sent on your phone number </p>
                </div>
                <form onSubmit={handleVerifyClick}>
                    <div className='position-relative'>
                        <div className='wrap-fildset'>
                            <input className='number-fild' type='text' disabled placeholder='Enter your mobile number' value={`+91 ${mobileNumber}`} />
                            <Link className='changelink' to={`/${userId}/login`}>Change</Link>
                        </div>
                        <label className='floatlavel'>Mobile Number</label>
                    </div>

                    <div className='group-inputs mt-4'>
                        {values.map((value, index) => (
                            <input
                                key={index}
                                type='number'
                                value={value}
                                onChange={(e) => handleInputChange(index, e)}
                                ref={(el) => (inputRefs.current[index] = el)} // Assign refs to each input
                            />
                        ))}
                    </div>
                    {loginstatus === 0 && (
                        <div className="form-check mt-5 pl-3" id='trm-c'>
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id="flexCheckChecked"
                                checked={isCheckboxChecked}
                                onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                            />
                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                I agree to the <Link className='tc-link' to={"#"}>Terms and Conditions</Link>
                            </label>
                        </div>
                    )}

                    <CommonButton name={"Verify & Login "} onClick={handleVerifyClick} />

                    <div className='d-flex justify-content-between mt-3'>
                        <div className='text-md'>Didn’t receive an OTP?<button
                            className={`resend border-0 bg-transparent ${timer > 0 ? 'resend-disabled' : ''}`}
                            onClick={handleResendClick}
                            disabled={timer > 0}
                        >
                            Resend
                        </button></div>
                        <div className='timer-text'>{formatTimer(timer)}</div>
                    </div>
                </form>
                {errorMessage && <p className='error-message pt-2 text-danger'>{errorMessage}</p>}
            </div>
        </CommonLayout>
    );
}

export default OTPVerification;
