import React from "react";
import { useSelector } from "react-redux";

function ProfileInfo() {
  const userImg = useSelector((state) => state.fetchUser?.user?.profileImg);
  const userName = useSelector((state) => state.fetchUser?.user?.name);
  const userBio = useSelector((state) => state.fetchUser?.user?.bio);

  return (
    <>
      <div className="Profile_details">
        <img
          style={{ borderRadius: "50%" }}
          src={userImg ? userImg : "assets/images/defultprofileimage.png"}
          width="100"
          height="100"
          alt=""
        />
        <div className="main-details">
          <h4>{userName}</h4>
          <p>{userBio}</p>
        </div>
      </div>
    </>
  );
}

export default ProfileInfo;
