import React, { useContext, useEffect } from "react";
import ProductList from "../components/ProductList";
import CommonLayout from "../layout/CommonLayout";


const Home = () => {
  return (
    <CommonLayout>
      <ProductList />
    </CommonLayout>
  );
};

export default Home;
