import React from 'react'
import CommonLayout from '../layout/CommonLayout'
import BackClick from '../components/BackClick'
import tick from "../assets/images/icons/tickicon.svg";
import CommonButton from '../components/CommonButton';
import { useNavigate } from 'react-router-dom';

function Kyc() {
const navigate = useNavigate();
    const handelkycclosebtn = () =>{
        navigate('/Kyc?sucess=true')
    }
    return (
        <>
            <CommonLayout>
                <BackClick />
                <div
                    style={{
                        marginTop: "50px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "18px",
                    }}
                >
                    <img height={"70px"} width={"70px"} src={tick} alt="" />
                    <p
                        style={{
                            fontWeight: "800",
                            fontSize: "30",
                            color: "#000",
                            marginBottom: "0px",
                        }}
                    >
                        Your KYC Uploaded Sucessfully  
                    </p>
                    <p>(Please click the close button To return App)</p>

                    <CommonButton
                        name={"Close"}
                        width={"80%"}
                        onClick={handelkycclosebtn}
                    />
                </div>
            </CommonLayout>

        </>
    )
}

export default Kyc