import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../config";
import VariantList from "../components/VariantList";
import ProductsMapList from "../components/ProductsMapList";
import { useDispatch, useSelector } from "react-redux";
import CommonLayout from "../layout/CommonLayout";
import { getModalState } from "../reducer/manageModalStateSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { getList } from "../reducer/productListSlice";
import ShareButton from "../components/modal/WebShareBtn";
import { getSizeChart } from "../reducer/commonValuesSlice";
import { isVideo } from "../utils/utils";
import ImgOrVideo from "../components/ImgOrVideo";
import sizechart from "../assets/images/no-image-available.jpg";
import CommonModal from "../components/modal/CommonModal";


function ProductDetails({ setCartLength }) {
  const [loading, setLoading] = useState(false);
  const [productDetails, setProductDetails] = useState();
  const [productAttributes, setProductAttributes] = useState();
  const [firstMatchingVariant, setFirstMatchingVariant] = useState();
  const [selectedVariantValues, setSelectedVariantValues] = useState({});
  const [variantlength, setvariantlength] = useState(0);
  const [dynamicIds, setDynamicIds] = useState({});
  const [indexOne, setIndexone] = useState(null);
  const [indexTwo, setIndextwo] = useState(null);
  const [isInCart, setIsInCart] = useState();
  const [returnpolicydata, setreturnpolicydata] = useState([]);
  const [returnpolicy, setreturnpolicy] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { productId, userId } = useParams();
  const uid = useSelector((state) => state.fetchUser?.user?.id);
  const SelectedVariantStock = parseInt(localStorage.getItem('selectedStock'));
  useEffect(() => { if (userId) { localStorage.setItem("userid", userId); } }, [userId]);
  const pId = useSelector((state) => state.productslst?.particularId);
  const staticUrl = `${baseUrl}productdetails/${userId && userId}/${pId ? pId : productId}`;
  const productList = useSelector((store) => store.productslst?.products);
  const storeId = localStorage.getItem("storeid");
  const [constItemDetails, setConstItemDetails] = useState({
    title: "",
    des: "",
    img: [],
  });
  const [ProductVariantLength, setProductVariantLength] = useState();

  const isOutOfStock = ProductVariantLength?.length === 0 && productDetails.stock === 0;
  const isOutofStockVar =  ProductVariantLength?.length != 0 && SelectedVariantStock === 0;



  useEffect(() => {
    const productVariants = productDetails?.additionalInfo?.productVariants;
    const firstProductVariants = productVariants?.[0]?.variants || [];
    const uniqueVariants = firstProductVariants.filter((variant, index, self) =>
      index === self.findIndex((v) => v.value._id === variant.value._id)
    );
    setvariantlength(uniqueVariants.length);
  }, [productDetails, indexOne, indexTwo, productId]);



  const fetchProductDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${apiBaseUrl}user/get-seller-product-details/${uid && uid
        }/${productId}`
      );
      const data = await response.json();
      setLoading(false);
      setProductDetails(data?.product);
      dispatch(getSizeChart(data?.product?.sizeChart));
      setProductAttributes(data?.product?.additionalInfo?.attributes);
      setProductVariantLength(data?.product?.additionalInfo?.productVariants)
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchProductList = async () => {
    try {
      const response = await fetch(
        `${apiBaseUrl}user/get-related-products/${productId}`
      );
      const data = await response.json();
      dispatch(getList(data?.products));
    } catch (error) {
      console.error("Error fetching Products:", error);
    }
  };

  const fetchreturnPolicy = async () => {
    try {
      const response = await fetch(`${apiBaseUrl}rPolicy/getReturnPolicy`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: storeId,
        }),
      });
      const data = await response.json();
      setreturnpolicydata(data);
      setreturnpolicy(data?.data?.description)
    } catch (error) {
      console.error("Error fetching cancellation policy:", error);
    }
  };


  useEffect(() => {
    fetchProductList();
    fetchreturnPolicy();
  }, []);

  useEffect(() => {
    if (productId && uid !== undefined) {
      fetchProductDetails();
      fetchProductList();
    }
  }, [productId, uid, dispatch]);

  useEffect(() => {
    if (productDetails) {
      setConstItemDetails({
        title: productDetails?.title,
        des: productDetails?.description,
        img: productDetails?.images,
      });
    }
  }, [productDetails]);

  const updateState = () => {
    const newDynamicIds = Object.entries(selectedVariantValues).reduce((acc, [key, value]) => {
      acc[key] = value?.id;
      return acc;
    }, {});
    setDynamicIds((prev) => {
      const isSame = Object.keys(newDynamicIds).every(
        (key) => prev[key] === newDynamicIds[key]
      );
      return isSame ? prev : newDynamicIds;
    });
  };

  useEffect(() => {
    updateState();
  }, [selectedVariantValues, variantlength]);


  useEffect(() => {
    if (dynamicIds[Object.keys(dynamicIds)[0]]) {
      setIndexone(dynamicIds[Object.keys(dynamicIds)[0]]);
    }
    if (dynamicIds[Object.keys(dynamicIds)[1]]) {
      setIndextwo(dynamicIds[Object.keys(dynamicIds)[1]]);
    }
  }, [dynamicIds]);


  const onVariantSelect = (selectedValues) => {
    setSelectedVariantValues(selectedValues);
  };

  useEffect(() => {
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

    const productExistsInCart = existingCart.some((item) => {
      if (variantlength === 0 && item?.id === productId) {
        setIsInCart(true);
        return true;
      } else if (
        variantlength === 1 &&
        item.variant?.firstVariant?.title?.id === indexOne && item?.id === productId
      ) {
        setIsInCart(true);
        return true;
      } else if (
        variantlength === 2 &&
        item.variant?.firstVariant?.title?.id === indexOne && item?.id === productId &&
        item.variant?.secondVariant?.title?.id === indexTwo
      ) {
        setIsInCart(true);
        return true;
      } else {
        return false;
      }
    });

    setIsInCart(productExistsInCart);
  }, [selectedVariantValues, indexOne, indexTwo, variantlength, productId]);


  const handleAddToCart = () => {
    const isVideoProduct =
      constItemDetails?.img?.[0] && isVideo(constItemDetails.img[0]);
    const productImage = isVideoProduct ? sizechart : constItemDetails?.img?.[0];

    // Prepare the selected variant data
    const variant = selectedVariantValues
      ? Object.entries(selectedVariantValues).reduce((acc, [key, value], index) => {
        const variantKey =
          index === 0
            ? "firstVariant"
            : index === 1
              ? "secondVariant"
              : `variant${index + 1}`;
        acc[variantKey] = { title: value };
        return acc;
      }, {})
      : null;

    // Prepare the product data to be added to the cart
    const productData = {
      id: productDetails?.id,
      title: productDetails?.title,
      price:
        firstMatchingVariant?.price ||
        (productDetails?.price === 0 ? productDetails?.mrp : productDetails?.price),
      image: productImage,
      quantity: 1,
      storeid: storeId,
      variant: variant,
    };

    // Get the existing cart from local storage
    const existingCart = JSON.parse(localStorage.getItem("cart")) || [];

    // Check if the product already exists in the cart
    const productExists = existingCart.some(
      (item) =>
        item.id === productData.id &&
        JSON.stringify(item.variant) === JSON.stringify(productData.variant)
    );

    let updatedCart;
    if (productExists) {
      // Update the quantity if the product already exists in the cart
      updatedCart = existingCart.map((item) => {
        if (
          item.id === productData.id &&
          JSON.stringify(item.variant) === JSON.stringify(productData.variant)
        ) {
          return {
            ...item,
            quantity: item.quantity + 1, // Increment quantity
          };
        }
        return item;
      });
    } else {
      // Add the new product to the cart
      updatedCart = [...existingCart, productData];
    }

    // Save the updated cart to local storage
    localStorage.setItem("cart", JSON.stringify(updatedCart));
    const filteredCart = updatedCart.filter((item) => item.storeid === storeId);
    setCartLength(filteredCart.length);
    setIsInCart(true);

    // Show the modal confirming that the item has been added to the cart
    const addCartModal = new window.bootstrap.Modal(
      document.getElementById("addCart")
    );
    addCartModal.show();

    // Handle modal cleanup after it's hidden
    const modalElement = document.getElementById("addCart");
    const handleModalCleanup = () => {
      document.body.style.overflow = "";
      document.body.style.paddingRight = "";

      // Remove the backdrop element if present
      const backdropElement = document.querySelector(".modal-backdrop");
      if (backdropElement) {
        backdropElement.remove();
      }

      // Remove event listener after cleanup
      modalElement.removeEventListener("hidden.bs.modal", handleModalCleanup);
    };

    modalElement.addEventListener("hidden.bs.modal", handleModalCleanup);
  };




  const handlegoToCart = () => {
    navigate(`/${userId}/cart`);
  }


  return (
    <CommonLayout >
      <div className="d-flex justify-content-between  ProductItemContainer p-0">
        <div className="ProductItemDetails">
          <div className="ItemStatus">
            <span>HOT</span>
          </div>
          <Swiper
            modules={[Pagination]}
            pagination={{ clickable: true }}
            spaceBetween={50}
            slidesPerView={1}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {constItemDetails?.img?.map((item, index) => (
              <SwiperSlide key={index}>


                <ImgOrVideo url={item} type={1} />
              </SwiperSlide>
            ))}
          </Swiper>
          <div className="p_viewAll">
            <img src="/Images/Icons/Viewall.png" alt="" />
            <ShareButton url={staticUrl && staticUrl}>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.625 3.5625C19.625 5.49609 18.0781 7 16.1875 7C15.1992 7 14.2969 6.61328 13.6523 5.92578L7.16406 9.19141C7.20703 9.36328 7.25 9.57812 7.25 9.75C7.25 9.96484 7.20703 10.1797 7.16406 10.3516L13.6523 13.5742C14.2969 12.9297 15.1992 12.5 16.1875 12.5C18.0781 12.5 19.625 14.0469 19.625 15.9375C19.625 17.8711 18.0781 19.375 16.1875 19.375C14.2539 19.375 12.75 17.8711 12.75 15.9375C12.75 15.5508 12.793 15.1211 12.9219 14.7773L6.69141 11.6406C6.04688 12.5859 5.01562 13.1875 3.8125 13.1875C1.87891 13.1875 0.375 11.6836 0.375 9.75C0.375 7.85938 1.87891 6.3125 3.8125 6.3125C5.01562 6.3125 6.04688 6.95703 6.69141 7.90234L12.9219 4.76562C12.793 4.37891 12.75 3.99219 12.75 3.5625C12.75 1.67188 14.2539 0.125 16.1875 0.125C18.0781 0.125 19.625 1.67188 19.625 3.5625ZM3.76953 11.8125C4.92969 11.8125 5.83203 10.9102 5.83203 9.75C5.83203 8.63281 4.92969 7.6875 3.76953 7.6875C2.65234 7.6875 1.70703 8.63281 1.70703 9.75C1.70703 10.9102 2.65234 11.8125 3.76953 11.8125ZM16.1875 1.5C15.0273 1.5 14.125 2.44531 14.125 3.5625C14.125 4.72266 15.0273 5.625 16.1875 5.625C17.3047 5.625 18.25 4.72266 18.25 3.5625C18.25 2.44531 17.3047 1.5 16.1875 1.5ZM16.1875 18C17.3047 18 18.25 17.0977 18.25 15.9375C18.25 14.8203 17.3047 13.875 16.1875 13.875C15.0273 13.875 14.125 14.8203 14.125 15.9375C14.125 17.0977 15.0273 18 16.1875 18Z"
                  fill="#FBFBFB"
                />
              </svg>
            </ShareButton>

          </div>
        </div>
      </div>

      <div className="product-des">
        <div className="row">
          <h5 className="p-0">{constItemDetails?.title}</h5>
          <div className="col-7 p-0">
            <div className="price">
              <span>
                {productDetails?.price === 0 ? "" :
                  <del>
                    Rs.{" "}
                    {firstMatchingVariant?.mrp
                      ? firstMatchingVariant?.mrp
                      : productDetails?.mrp}
                  </del>
                }
              </span>
              <span className={productDetails?.price === 0 ? "main-price ps-0" : "main-price"}>
                Rs.{" "}
                {firstMatchingVariant?.price
                  ? firstMatchingVariant.price
                  : productDetails?.price === 0
                    ? productDetails?.mrp
                    : productDetails?.price}
              </span>
            </div>
            <p>Price inclusive of taxes</p>
          </div>
          <div className="col-5 d-flex align-items-center p-0">
            {isOutOfStock || isOutofStockVar ? (
              <button className="out-of-stock">Out Of Stock</button>
            ) : isInCart ? (
              <button className="add_to_cart_brn" onClick={handlegoToCart}>
                Go to Cart
              </button>
            ) : (
              <button
                className="add_to_cart_brn"
                data-bs-toggle="modal"
                data-bs-target="#addCart"
                onClick={handleAddToCart}
              >
                Add to Cart
              </button>
            )}
          </div>
        </div>
      </div >
      <div
        style={{
          borderBottom: "1px solid #677B8A50",
          margin: "7px",
          paddingBottom: "13px",
        }}
      >
        <VariantList
          productVariants={productDetails?.additionalInfo?.productVariants}
          onVariantSelect={onVariantSelect}
        />
      </div>
      <div className="size-chart">
        <p>Check Size</p>
        <button data-bs-toggle="modal" data-bs-target="#Sizechart">
          Size chart
        </button>
      </div>

      <div className="Variant-group-des">
        <p>{constItemDetails?.des}</p>
      </div>
      {
        productAttributes?.length > 0 && (
          <div className="product-additional-status">
            <div className="row">
              {productAttributes?.map((item, index) => (
                <div className="col-6">
                  <strong>{item.title}:</strong>
                  <span>
                    {item.values?.map((value, idx) => (
                      <span key={idx}>
                        {value.title}
                        {idx < item.values.length - 1 && ", "}
                      </span>
                    ))}
                  </span>
                </div>
              ))}
            </div>

          </div>
        )
      }

      <div className="cr">
        <div className="row">
          <div className="col-6 ps-0">
            <strong>
              COD: <span> Not Available</span>
            </strong>
          </div>
          <div className="col-6">
            <strong>
              Replacement: <span>
                {returnpolicydata?.data?.status === 1 ?
                  `${returnpolicydata?.data?.noOfDay} day`
                  :
                  "No"
                } </span>
              {returnpolicydata?.data?.status === 1 ? <Link id="rfpl" onClick={handleShow}>Return & Replacement Policy </Link> : ""}
            </strong>
          </div>
        </div>
      </div>

      <div
        className="hello-store-brn"
        data-bs-toggle="modal"
        data-bs-target="#socialshare"
        onClick={() => dispatch(getModalState(true))}
      >
        <h6>Have a query? Chat with the Seller</h6>
      </div>

      <div className="collection-title">
        <h4>You may also like</h4>
      </div>
      <div className="d-flex justify-content-between  ProductItemContainer">

        <ProductsMapList productList={productList && productList} />
        {/* <ShareModal /> */}
      </div>
      <CommonModal
        show={show}
        onHide={handleClose}
        handleClose={handleClose}
        title="Return & Replacement Policy"
        content={returnpolicy}
      />
    </CommonLayout >
  );
}

export default ProductDetails;
