import React from "react";
import cartSvg from "../assets/images/icons/cart.svg";
import { useNavigate, useParams } from "react-router-dom";
function ViewCartButton() {
  const storeId = localStorage.getItem('storeid');
  const cart = JSON.parse(localStorage.getItem("cart")) || [];
  const cartLength =cart.filter((item) => item.storeid === storeId).length;
  const navigate = useNavigate();
  const { userId } = useParams();
  return (
    <button
      className="btn "
      id="cartbtn"
      style={{
        display: "flex",
        width: "28%",
        padding: "10px",
        borderRadius: "10px",
        backgroundColor: "#3371A5",
        color: "#fff",
        alignItems: "center",
        justifyContent: "center",
        position: "fixed",
        bottom: "14px",
        left: "0",
        right: "0",
        marginLeft: "auto",
        marginRight: "auto",
        gap: "14px",
      }}
      onClick={() => navigate(`/${userId}/cart`)}
    >
      <p style={{ marginBottom: "0px" }}>View Cart</p>
      <div className="position-relative">
        <img src={cartSvg} alt="" />
        <div style={{left:'10px'}} className="d-flex justify-content-center align-items-center cart-batch">
          {cartLength}
        </div>
      </div>
    </button>
  );
}

export default ViewCartButton;
