import React from 'react'
import Modal from 'react-bootstrap/Modal';

export default function CommonModal({ show, handleClose, title, content }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered >
        <Modal.Header closeButton>
          <Modal.Title >{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <pre>
          {content}
          </pre>
        </Modal.Body>
      </Modal>
    </>
  )
}
