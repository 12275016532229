import React from 'react'
import { Link } from 'react-router-dom'

function TermsConditionpreview() {
    return (
        <>
            This document (<strong>“Terms”</strong>) is an electronic record in terms of Information Technology Act, 2000
            and rules thereunder, and other applicable law and is generated by a computer system and does not
            require any physical or digital signatures. The Company agrees to process the data in accordance
            with lawful and reasonable written instructions provided by the Seller.
            <br /><br />
            <strong>1. Applicability to Products</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>1.1. The Company, Upquark Phygitech Private Limited, a private company established
                    under the laws of India, has a Taptohello (“platform”) which operates and provides
                    two distinct features: Hellostore and Hellomall.</li><br />
                <li>1.2. Hellostore is a platform that enables sellers to create and manage their online stores
                    by offering tools for catalogue creation, product collections, promotional offers,
                    discounts, and other relevant links.Sellers on HelloStore can customize and curate
                    their product listings to offer seamless shopping experience to their social media
                    followers, thereby increasing their chances of better conversion rate.</li><br />
                <li>1.3. Hellomall, on the other hand, is a comprehensive marketplace that aggregates
                    various social sellers into a single platform, offering buyers a diverse range of
                    products from designers, fashion sellers, boutique owners, direct-to-consumer
                    (D2C) fashion manufacturers, and other category sellers. Hellomall is designed to
                    provide an enriched shopping experience by offering a curated selection of fashion
                    and other items from various sellers, creating a dynamic and expansive
                    marketplace.</li><br />
                <li>1.4. These Terms and Conditions apply uniformly to both Hellostore and Hellomall,
                    and by accessing or using either of these features, you as a buyer agree to be bound
                    by the terms outlined herein. Taptohello reserves the right to modify, update, or
                    amend these Terms and Conditions as necessary, and it is your responsibility to
                    review these Terms periodically to stay informed of any changes that may impact
                    your use of the Company’s products.</li>
            </ul>
            <strong>2. Introduction</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>2.1. These terms and conditions <strong>(“Terms”)</strong> govern the use of services made available
                    on or through Taptohello website and/or the mobile app (collectively, the
                    <strong>“Platform”</strong>, and together with the services made available on or through the
                    Platform, the <strong>“Services”</strong>). These Terms also include our privacy policy, available
                    at <Link style={{ backgroundColor: 'yellow', color: 'black' }} to="https://taptohello.com/privacy" target='_blank' >[https://taptohello.com/privacy]</Link> (<strong>“Privacy Policy”</strong>), which form an essential
                    part of these Terms. By accessing and using the Services, you accept these Terms
                    and Conditions, without limitation or qualification. If you do not agree to the Terms
                    and Conditions, do not use the Services. If you represent any other person, you
                    confirm and represent that you have the necessary power and authority to bind such
                    person to these Terms.</li><br />
                <li>2.2The TaptoHello Platform is made available by Upquark Phygitech Private Limited
                    (<strong>“Hellomall”, “HelloStore”, “Company”, “we”, “us” and “our”</strong>), a private
                    company established under the laws of India, having its registered office at A2-604,
                    Cleo County, Sector 121, Noida, Uttar Pradesh, 201301</li>
            </ul>

            <strong>"Sellers"</strong> refers to individuals or entities who utilize their accounts on the Taptohello Platform to
            create and manage their own online stores <strong>(“Stores”)</strong> which can be accessed by customers
            <strong>(“Buyers'')</strong> where they can make their purchases. Sellers can also avail various Services including
            from third party service providers  <strong>(“Partners”)</strong>.<br /><br />

            Sellers and the Buyers shall hereinafter be referred to as the "Parties" in the collective and as a
            "Party" in the singular, as the context may so require.<br /><br />

            The term “Services” refers to any services offered by Taptohello, including but not limited to:<br /><br />
            <ul>
                <li>Providing Sellers with access to the Taptohello Platform, which includes a suite of tools,
                    catalogue, software, features, and functionality. This integrated Software as a Service
                    <strong>(SAAS)</strong> solution enables Sellers to register, create, and manage customised online Stores
                    for Buyers to purchase products.</li><br />
                <li>Offering Sellers subdirectory within the Taptohello Domain to host their Stores, making
                    them accessible to Buyers</li><br />
                <li>Facilitating access to a variety of additional services either directly or through our Partners,
                    including but not limited to delivery, logistics, payment solutions, and advertising</li>
            </ul>
            <div className='py-1'>(Collectively, <strong>“Services”</strong>)</div>

            These guidelines are designed to help buyers understand their rights and responsibilities when
            using the Taptohello platform ("Platform"). As a Buyer, you agree to adhere to the obligations set
            forth in these Terms.<br /><br />

            <strong>3. Purpose and Understanding</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>3.1. These Conditions shall apply as between the Company and the Customers
                    <strong>(“Buyers”)</strong></li><br />
                <li>3.2. A number of words are used in these Conditions which have special meanings.
                    Where this is the case the relevant words as defined begin with a capital letter. The
                    meanings of these specially defined words and other guides to understanding these
                    Conditions can be found in the Glossary at the end of these Conditions.</li>
            </ul>

            <strong>4. Eligibility</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>4.1. Persons who are "incompetent to contract" within the meaning of the Indian
                    Contract Act, 1872, are not eligible to use the Platform. If you are under the age of
                    18 years, you shall not register on the Platform. Company reserves the right to
                    terminate your registration and/or refuse you access to the Platform if it is brought
                    to Upquark’ notice or discovered that you are under the age of 18 years. </li><br />

                <li>4.2. Additionally, you affirm that you are legally permitted to use this website and to
                    enter into legally binding agreements under applicable law. Your use of this website
                    constitutes your representation that you meet these eligibility requirements. If you
                    do not meet these requirements, you must not use this website</li>
            </ul>

            <strong>5. Amendments</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>5.1. Taptohello is operated, and Services are provided in compliance with the laws in
                    India and we shall not be liable to provide any Services to you in locations outside
                    Indian jurisdiction. If you as a Buyer access our Services from locations outside
                    India, you do so at your own risk and you are solely liable for compliance with
                    applicable local laws. Where you use any third-party website or the services of any
                    third party such as a payment gateway or logistics partner, you may be subject to
                    alternative or <strong>additional terms and conditions</strong> of use and privacy policies</li>
            </ul>

            <strong>6. Services For Buyers:</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>6.1. When you, as a Customer, visit or make a purchase from a Store created by any
                    Seller on the Platform, you acknowledge that you are entering into a direct
                    relationship with the Sellers operating that Store. The Company merely provides
                    the Platform and does not have any control over the products or services offered by
                    the Sellers through their Stores.Additionally, the Company has no control over the
                    fulfilment of orders, including the shipping, delivery, or quality of the products or
                    services. All queries or issues related to your purchase should be directed to the
                    respective Seller, as they are solely responsible for fulfilling the orders and
                    providing customer support</li><br />

                <li>6.2. The Company does not assume any responsibility for the authenticity, accuracy, or
                    legality of the products or services provided by the Sellers. The responsibility for
                    ensuring that the products and services comply with applicable laws and regulations
                    rests solely with the Parties. Consequently, the Company shall not be held liable
                    for any transactions conducted through the Stores or for any damages or losses
                    incurred by the Customer as a result of such transactions.
                </li><br />

                <li>6.3. While the Company requires Sellers to operate their Stores in compliance with
                    applicable laws, buyers acknowledge that the Company is not responsible for, and
                    has no control over, the practices, content, or terms of use of the Stores or the thirdparty partners providing services on the Platform. Customers are encouraged to
                    review refund & return policies of the relevant Store and third-party partners to
                    understand how those terms govern their relationship with the Sellers
                </li><br />

                <li>6.4. In the event of any disputes or issues arising from transactions conducted through
                    the Stores, Customers are advised to contact the relevant Sellers directly. The
                    Company shall not mediate or resolve disputes between Customers and Sellers, nor
                    shall the Company be liable for any damages or losses resulting from such disputes.
                </li><br />

                <li>6.5. If you, as a Buyer, make a purchase through our Platform, you agree that the
                    Company is not responsible for any issues related to your purchase, including but
                    not limited to logistical delays, shipping errors, payment failures, or any fraudulent
                    activity by Sellers or third-party service providers. The Company acts solely as a
                    platform provider, and all transactions are between you and the Seller or third-party
                    service providers. Any disputes, losses, or damages must be resolved directly with
                    the Seller or the relevant third party, as the Company disclaims all liability in such
                    cases.

                </li>
            </ul>

            <strong>7. Marketing and Communication Clause</strong><br /><br />

            By agreeing to these Terms & Conditions, parties consents to being contacted by TaptoHello or
            its associates/subsidiaries for any marketing or solicitation activities, including but not limited to
            promotional offers, updates, and information about new products or services. Buyers acknowledge
            and agree that this consent applies even if they are registered on any "Do Not Disturb" (DND) or
            similar registry. Further, buyers expressly waive any rights that they may have under such
            registries to prevent TaptoHello from contacting them for the aforementioned purposes. This
            consent may be revoked at any time by contacting TaptoHello’s customer service through the
            provided channels, in which case TaptoHello will cease further marketing communications within
            a reasonable timeframe.

            <strong>8. Charges and Payments</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>8.1. Payments for purchases must be made using the payment methods available on the
                    Platform. Taptohello uses third-party payment gateways, and your payment
                    information is securely processed in accordance with the applicable laws.</li><br />

                <li>8.2. Prices listed may be inclusive or exclusive of applicable taxes, as determined by
                    the seller. You are responsible for paying any taxes or fees associated with your
                    purchase </li><br />

                <li>8.3. In the event of any payment-related issues in <strong>HelloStore</strong>, you are advised to first
                    contact the Seller directly to resolve the matter. If the issue remains unresolved,
                    you may then contact the Payment Gateway provider, who will assist you in
                    reaching a resolution. For transactions made through <strong>HelloMall</strong>, an escalation
                    matrix is in place to ensure your concerns are addressed promptly.</li>
            </ul>

            <strong>9. Account Registration, Password Protection, and Security</strong><br /><br />
            <ul style={{ listStyle: 'none', padding: '0px' }}>
                <li>9.1. <strong>For Customers:</strong></li>
                <ul style={{ listStyle: 'none' }}>
                    <li>9.1.1. You must provide accurate and complete information during the registration
                        process. This includes and is not limited to your name, contact details,
                        payment information etc. Providing false or misleading information may
                        result in suspension or termination of your account.</li><br />
                    <li>
                        9.1.2. You can access HelloStore, the e-Commerce website hosted on the
                        Taptohello Platform, in several ways: directly through the Hello Link
                        (unique URL), via banners or links, or through your account on social media
                        platform or other third-party services (“Third Party Account”).
                        Additionally, you can access HelloStore through the QR Code provided by
                        the respective social sellers.

                    </li><br />
                    <li>9.1.3. You are responsible for maintaining the confidentiality of your Account
                        Information and for all activities under your Account. You agree to
                        immediately notify the Company of any unauthorised use or security
                        breaches. The Company will not be liable for any loss or damage arising
                        from your failure to secure your Account. You may be held responsible for
                        losses incurred by the Company or others due to unauthorised use of your
                        Account resulting from your failure to maintain confidentiality. Using
                        another user’s Account Information is strictly prohibited. The Company is
                        not liable for any financial loss, incon</li>
                </ul>
            </ul>
            <strong>10. Warranty</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>10.1. The Company makes no express warranties and specifically disclaims any implied
                    warranties, including any implied warranty of merchantability or fitness for a
                    particular purpose, with respect to the performance of Services under the Contract
                    and these Conditions to the extent permissible by law.</li><br />

                <li>10.2. The Company does not guarantee, and nothing contained in the Contract or these
                    Conditions shall be construed as a guarantee, that the Service performed or to be
                    performed by the Seller will achieve any projected level of results.</li>
            </ul>

            <strong>11. Indemnification and Limitation of Liability</strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>11.1. You agree to indemnify, defend, and hold harmless the Company, including its
                    affiliates, vendors, representatives, shareholders, directors, agents, and employees,
                    from any and all losses, liabilities, claims, damages, demands, costs, and expenses
                    (including legal fees and interest) arising from or related to any breach or nonperformance of your obligations under these Terms. This includes, but is not limited
                    to, any claims by third parties due to your use of the Taptohello Platform, any
                    damages caused by your material to third parties, or your violation of these Terms
                    or any rights of others, including intellectual property rights</li><br />
                <li>11.2. The Company is not responsible for any non-performance or breach of contracts
                    between buyers and third-party service providers. The Company does not guarantee
                    the performance of any transactions entered into on the Taptohello Platform and
                    shall not mediate or resolve disputes between users and third-party service
                    providers. The Company does not hold any rights, title, or interest in the products
                    sold through the Stores, nor does it assume any obligations or liabilities related to
                    such transactions. These limitations and exclusions apply to the fullest extent
                    permitted by applicable law.</li><br />
                <li>11.3. Buyer agrees and acknowledges that the allocation of risk in this clause is fair and
                    reasonable in the circumstances having been taken into account by the Company in
                    setting the level of Charges and agreeing the extent of Service.</li><br />
            </ul>

            <strong>12. Term and Termination </strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>12.1. These Terms shall remain effective as long as you continue to access and use the
                    Taptohello Platform. The Company reserves the right to suspend or terminate your
                    access to the Platform and its Services, at its sole discretion, at any time, including
                    but not limited to instances where you are found to be in breach of these Terms.</li><br />

                <li>12.2. Upon termination of your Account, all rights granted to you under these Terms will
                    immediately cease, and your access to the Taptohello Platform will be revoked.
                    You acknowledge that termination may involve the deletion of any User Content
                    or data associated with your Account from our live databases. The Company shall
                    not be liable for any consequences, including loss of data, resulting from the
                    termination of your Account or rights under these Terms</li><br />

                <li>12.3. Termination does not relieve you of any obligations accrued before termination,
                    nor does it limit any liability you may have to the Company or any third party. The
                    Company reserves the right to pursue all legal remedies available to it, including
                    injunctive relief, in case of any breach of these Terms. Further, if Company does
                    take any legal action against you as a result of your violation of these Terms,
                    Company will be entitled to recover from you, and you agree to pay all reasonable
                    attorneys' fees and costs of such action, in addition to any other relief granted to
                    Company.
                </li><br />
            </ul>

            <strong>13. Term and Termination </strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>
                    13.1. We cannot and do not guarantee that other users will comply with the rules outlined
                    in these Terms or any other applicable provisions. You acknowledge and accept
                    that you bear all risks of harm or injury that may arise from such non-compliance
                    by other users
                </li><br />
                <li>
                    13.2. When you click on a link that directs you away from the Taptohello Platform, you
                    understand that you are entering a site not controlled by us. These external sites
                    may have their own terms of use and privacy policies, which may differ from ours.
                    By accessing such links, you acknowledge that Taptohello is not responsible for
                    the content, policies, or practices of these external sites. While we reserve the right
                    to disable links to or from third-party sites to the Taptohello Platform, we are under
                    no obligation to do so.
                </li><br />
            </ul>

            <strong>14. Obligation of a Buyer </strong><br /><br />
            As a user of the Taptohello Platform, you agree to the following obligations:
            <br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>14.1. Buyer shall comply with all applicable laws, regulations, and guidelines in your use
                    of the Taptohello Platform and Services. You agree not to engage in any unlawful
                    activities or violate these Terms. Further, the party is responsible for all content and
                    materials uploaded or posted on the Taptohello Platform by the buyer itself. You
                    must ensure that your content is accurate, lawful, and does not infringe on the rights
                    of others.</li><br />
                <li>14.2. Buyer should not use any automated means such as data scraper, deep-link, robot,
                    spider or other automatic device, program, algorithm or methodology, or any
                    similar or equivalent manual process to access the Taptohello Platform, or Services
                    for any purpose</li><br />
                <li>14.3. Buyer shall not attempt to gain unauthorised access to any part of the Taptohello
                    Platform or engage in activities that compromise the security of the Platform or any
                    associated networks. Further, buyers must respect the privacy of others and comply
                    with all relevant privacy laws and shall not misuse personal information obtained
                    through the Taptohello Platform</li><br />
                <li>14.4. Buyer agrees not to upload or share content that is illegal, harmful, or offensive,
                    including content that infringes on the intellectual property rights of others or
                    violates applicable laws</li>
            </ul>
            By using the Taptohello Platform, you agree to adhere to these obligations, and failure to do so
            may result in suspension or termination of your Account, without liability to Taptohello.<br /><br />

            <strong>15. Company’s Rights </strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>15.1. Taptohello reserves the right to modify, update, or discontinue any aspect of the
                    Platform or Services at any time, without prior notice. This includes but is not
                    limited to changes in the functionality, features, or availability of the Platform or
                    Services.</li><br />
                <li>15.2. Taptohello has the right to disclose any information as necessary to comply with
                    applicable laws, regulations, legal processes, or governmental requests. This
                    includes the right to cooperate with law enforcement authorities or other third
                    parties in the investigation of alleged illegal activities or violations of these Terms.</li><br />

                <li>15.3. All commercial terms, such as pricing, delivery, and dispatch of products or
                    services, are strictly between the Seller and his customers as part of a direct
                    contractual relationship. The Company has no control over, and assumes no liability
                    for, the products or services listed on Seller’s Store. Additionally, the Company
                    does not guarantee the identity of any third party or that any transaction between a
                    Customer and a third party will be completed</li><br />

                <li>15.4. Any dispute in connection to the third-parties services shall be settled between the
                    Seller and the Customer, including without limitations, banks/payment
                    gateways/payment aggregators/network operators without involving the Company</li><br />

                <li>15.5. Any dispute in connection to the shipment of order or payment gateway between
                    the buyer and seller shall be settled by the seller only. If in any case the seller refuses
                    to intervene/help or take any action, the customer can send us an email with their
                    primary email after 30 days of such an issue</li>
            </ul>

            <strong>16. Intellectual Property </strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>16.1. All content, features, and functionality provided on or through the Taptohello
                    Platform, including but not limited to text, graphics, logos, icons, images, audio
                    clips, video clips, data compilations, software, and the arrangement thereof
                    (collectively, "Company Content"), are the exclusive property of Taptohello or its
                    licensors and are protected by applicable intellectual property laws, including but
                    not limited to copyright, trademark, and patent laws.</li><br />
                <li>16.2. You acknowledge that Taptohello and/or its licensors retain full ownership of all
                    Company Content made available on the Platform. This includes all intellectual
                    property rights associated with the Platform and the Services offered therein. Your
                    use of the Platform does not grant you any rights or licenses to use any Company
                    Content except as expressly provided in these Terms.</li><br />

                <li>16.3. All trademarks, service marks, trade names, logos, and other identifiers used on the
                    Taptohello Platform (collectively, "Trademarks") are proprietary to Taptohello or
                    its licensors. You may not use, copy, reproduce, or distribute any Trademarks
                    without the prior written consent of Taptohello.</li><br />

                <li>16.4. If you believe that any content on the Taptohello Platform infringes your
                    intellectual property rights, you may notify us by providing a written notice to our
                    designated contact address. The notice should include sufficient details to identify
                    the alleged infringement and your contact information. Taptohello will investigate
                    and take appropriate action, which may include removing the infringing content.</li><br />

                <li>16.5. You agree not to copy, modify, distribute, transmit, display, perform, reproduce,
                    publish, license, create derivative works from, transfer, or sell any Company
                    Content, information, software, or Services obtained from the Taptohello Platform
                    without prior written consent from Taptohello</li>
            </ul>
            <strong>17. Miscellaneous </strong><br /><br />
            <ul style={{ listStyle: 'none' }}>
                <li>17.1. If any part of this Agreement shall be declared invalid or unenforceable by a court
                    of competent jurisdiction, it shall not affect the validity of the balance of this
                    Agreement.</li><br />
                <li>17.2. In the event of a force major event that prevents, or hinders performance hereunder,
                    no default or liability for non-compliance occasioned thereby during the
                    continuance thereof shall exist or arise on Company.</li><br />

                <li>17.3. Company do not endorse any Seller who operates on Taptohello Platform.
                    Although these Terms require you to provide accurate information, we do not
                    attempt to confirm, and do not confirm if it is a purported identity. We will not be
                    responsible for any damage or harm resulting from your interactions with other
                    users of the Taptohello Platform</li><br />

                <li>17.4. We process information provided by you to us in accordance with our Privacy
                    Policy, available <Link to="https://taptohello.com/privacy" target='_blank'>https://taptohello.com/privacy</Link></li><br />

                <li>17.5. These terms shall be governed by and interpreted in accordance with the laws of
                    India The Licensee hereby consents to submit to the personal jurisdiction of Delhi
                    for all the purposes in connection with these terms. Each Party shall pay its own
                    fees, and other charges of its counsel</li><br />
                <li>17.6. Parties shall not assign or transfer or purport to assign or transfer the contract to
                    any other person. Company's rights under the Terms are freely transferable by
                    Company to any third parties without the requirement of seeking your consent.</li><br />
                <li>17.7. To ask questions or in case of any queries about these terms and our privacy
                    practices, contact us at: <Link to="mailto:support@taptohello.com">support@taptohello.com</Link></li><br />
            </ul>
            <strong>18. Glossary </strong><br /><br />
            In these Conditions the following words and phrases shall have the following meanings unless the
            context requires otherwise:<br /><br />
            <strong>Additional Services</strong> any other services other than the Services agreed to be provided by the
            Company to the Seller on agreed terms and set out as such in the Services Sheet or specification;<br /><br />
            <strong>Charges</strong> the Company’s charges as set out on the Charges Sheet or the Contract and from time to
            time for the provision of the Services unless otherwise expressly agreed with the Client;<br /><br />
            <strong>Charges Sheet</strong> the sheet or document setting out the applicable Charges in respect of the Services;<br /><br />
            <strong>GST/ VAT</strong>  means Value Added Tax or Goods and Services Tax, as applicable<br /><br />
            <strong>Company Material</strong> any Documents or other materials, and any data or other information provided by the Company in connection with or relating to the Services<br /><br />

            <strong>DISCLAIMER</strong><br /><br />
            <ol>
                <li>Before making a purchase, carefully review the product description, price, terms of sale,
                    shipping details, and return policy provided by the seller. Taptohello is not responsible for
                    the accuracy of the information provided by sellers.</li><br />
                <li>Prices listed by sellers on the Platform may change without prior notice. The availability
                    of products is subject to change based on the seller’s inventory</li><br />
                <li>Offers, discounts, and promotional codes are provided at the discretion of the seller. Ensure that you understand the terms and conditions associated with any promotional offer before
                    using it.</li><br />
                <li>Each seller on Taptohello has its own return, refund, and cancellation policy. You must
                    review these policies before making a purchase. If the seller does not offer returns, you
                    must accept the product as final sale. If the seller allows returns, you must initiate the return
                    within the specified time frame. The product should be in its original condition, with all
                    packaging, tags, and accessories intact</li><br />
                <li>Refunds are processed according to the seller’s policy. The refund amount will be credited
                    to your original payment method within the time frame specified by the seller.</li><br />
                <li>You must not engage in any fraudulent activities, including providing false information,
                    using stolen payment methods, or attempting to exploit the Platform’s systems. Any form
                    of abusive, threatening, or harassing behaviour towards sellers, other buyers, or Taptohello
                    staff will not be tolerated and may result in account suspension</li><br />
                <li>You must respect the intellectual property rights of sellers and Taptohello. Unauthorised
                    use, reproduction, or distribution of copyrighted materials is prohibited. You may cancel
                    an order before it is shipped. Once the order is shipped, it cannot be cancelled, and you
                    must follow the return process</li>
            </ol>
        </>
    )
}

export default TermsConditionpreview