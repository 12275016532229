import React from 'react'
import ProfileBanner from '../components/ProfileBanner'
import ProfileInfo from '../components/ProfileInfo'
import ProfileTab from '../components/ProfileTab'


const EditProfile = () => {
  return (
    <>
      <ProfileBanner />
      <ProfileInfo />
      <ProfileTab />
    </>
  )
}

export default EditProfile